/* side navigation update - 051624 */

#side-nav {
    @include phone-portrait {
        left: -80vw;
        width: 80vw;
        max-width: 80vw;
        background-color: $gray-extra-light;
    }
    .multi-level-sliding-navigation-main .multi-level-sliding-navigation-menu {
        padding: 0;
    }
    .multi-level-sliding-navigation-main li.heading {
        margin: 0;
        padding: .75rem 1rem .75rem;
        width: 100%;
        background: $maroon;
        span {
            display: block;
            margin-top: .35rem;
        }
    }
}

body.active #side-nav {
    box-shadow: 0 0 2rem $bg-overlay-extra-dark;
}

#global-ctas > button {
    border: solid .125rem $gold !important;
}

body.active #side-nav {
    @include phone-portrait {
        transform: translateX(80vw);
    }
}
#side-nav-search-wrapper form input:focus,
#side-nav-search-wrapper form button:focus {
    outline: solid $gold;
}

/* MENU */

nav#main-mobile {
    /*  display: inline-block; - to style */
        position: relative;
        top: 0;
        background: none !important;
        #menu-button-mobile {
            width: 2.25rem;
            opacity: 1;
            span.menu-bar-one, span.menu-bar-two, span.menu-bar-three, span.menu-bar-four {
            background: $maroon;
            }
            span.menu-text {
                bottom: 0;
                font-size: clamp(#{$font-step-075}, #{$font-step-075} + 3.5vw, #{$font-step-075});
                color: $maroon;
                // this is for the white header color: $maroon;
            }
        }
    }