/* image grid only */

.image-grid {
  background-image: none;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  .grid-wrapper-min-max {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    grid-template-rows: auto;
    grid-auto-flow: row;
    grid-auto-rows: auto;
    grid-row-gap: .25em;
    width: 100%;
    grid-gap: 0;
  }
  .grid-item {
    position: relative;
    margin: 0 !important;
    border: solid none;
  }
  img {
    margin: 0;
  }
  p.caption-overlay {
    position: absolute;
    bottom: -29px;
    left: 0px;
    padding: .5rem .5rem .5rem .5rem;
    width: 70%;
    height: auto;
    min-height: 75px;
    font-family: 'Open Sans', sans-serif;
    font-size: .75rem;
    color: $gray-extra-light;
    text-transform: uppercase;
    text-shadow: 0 0 15px $bg-overlay;
    border-radius: 0 10px 0 0;
    background: rgba(0,0,0,.35);
    span {
      display: block;
      width: 100%;
      font-family: 'Open Sans', sans-serif;
      line-height: 1.75;
    }
    span.caption-overlay-name {
      font-family: 'Open Sans', sans-serif;
      font-weight: 700;
      font-size: clamp(#{$font-step-085}, #{$font-step-085} + 3.5vw, #{$font-step-085});
    }
  }
}

/* image grid highlights */

/* specifically for image-grid-highlights - panel - height 50 */
.image-grid-highlights .grid-item .content-image-overlay.active,
.image-grid-highlights .grid-area .content-image-overlay.active {
  height: 80%;
  opacity: 1;
}

.image-grid-highlights {
  p.caption-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    min-height: 100%;
    text-align: center;
    border-radius: 0;
    background: rgba(0, 0, 0, 0.5);
    span {
      position: absolute;
      top: 4rem;
      left: 0;
      margin: 0 10%;
      width: calc(100% - 20%);
      font-family: 'Antenna Condensed Bold', sans-serif;
      font-size: clamp(#{$font-step-085}, #{$font-step-085} + 3.5vw, #{$font-step-085});
      line-height: clamp(#{$line-step-1-25}, #{$line-step-1-25} + 3.5vw, #{$line-step-1-25});
      text-shadow: 0 0 .5rem rgba(0,0,0,.35);
      @include tablet-portrait {
        font-size: clamp(#{$font-step-1-75}, #{$font-step-1-75} + 3.5vw, #{$font-step-1-75});
      }
      @include phone-landscape {
        font-size: clamp(#{$font-step-1-75}, #{$font-step-1-75} + 3.5vw, #{$font-step-1-75});
      }
      @include phone-portrait {
        font-size: clamp(#{$font-step-2}, #{$font-step-2} + 3.5vw, #{$font-step-2});
      }
    }
    span.caption-overlay-name {
      display: none;
    }
  }
}

/* gradient overlay */

.image-grid-highlights .grid-item .content-image-overlay {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.7)));
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 10%);
}

/* center alignment of symbol */

.image-grid.image-grid-highlights .content-activate-symbol {
  left: calc(50% - 1.125rem);
  cursor: default;
}

.image-grid.image-grid-highlights .content-activate-symbol.activate-symbol-plus {
  margin-bottom: .75rem;
  left: calc(50% - 1rem);
  cursor: default;
}